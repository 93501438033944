@import '../../..//styles/variables/Variable.scss';

.sidebar-container{
  box-shadow: 0 2px 5px 0 rgba(131, 141, 248, 0.05);
  // background-image: linear-gradient(15deg, #87CEEB, #0039a6);
  // background-image: linear-gradient(15deg, #87CEEB, #283890);
  background-image: linear-gradient(15deg, #0039a6, #87CEEB);
  height: 100vh;
  width: 100%;
  overflow: auto;

  @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
    width: 80px;
    text-align: center;
  }

  @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
    display: none;
  }
  >div{
    margin: 20px auto 20px auto;
    padding-top: 10px;
    width: 85px;
    > img {
        max-width: 100%;
        height: auto;
        @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
          display: none;
          padding:0px;
        }
        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
          display: none;
          padding:0px;
        }
    }
  }
  >ul{
    list-style: none;
    padding-left: 0px;
    border-top: #283890 2px solid;
    >li{ 
      >a{
        :hover{
          background-color: $grey;
          text-decoration: none;
        }
        >div{
          >span{
            color: $white !important;
          }
         }
      }
    }
    >li{
      margin-bottom: 10px;
      color:$white;
      >div{

        >div{
          >ul{
            >li{
              :hover{
                background-color: $grey;
              }
            }
          }
          >div{
            >span{
              color:$white !important;
            }
          }
        }
      }
    }
    ul {
        list-style: none;
        padding-left: 0px;
          
        > li {
          > a {
            display: flex;
            justify-content: flex-start;
            cursor: pointer;
            padding: 10px 30px 5px 65px;
            opacity: 0.7;
            color:black !important;
            &:active {
              > div {
                opacity: 1;
              }
            &:hover{
              text-decoration: none;
              color:black
            } 
            }
            > div {
              opacity: 0.7;
            }
          }
        }
    }
    .toggle{
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        padding: 10px 20px 10px 30px;
        opacity: 0.7;
        @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
          padding: 10px;
        }
        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
          padding: 5px;
        }
        > i {
          margin-right: 15px;
          width: 20px;
          color: $white;
          @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
            margin:0px auto;
          }
          @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            margin:0px auto;
          }
        }
        > img {
          margin-right: 15px;
          width: 20px;
          color: $white;
          @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
            margin:0px auto;
          }
          @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            margin:0px auto;
          }
        } 
        >span{
          @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
            display: none;
          }
          @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            display: none;
          }
        }
    }
  }
  
}
.hover_list{
  &:hover{
  background-color: $grey;
  }
}
