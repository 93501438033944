@import '../../../styles/variables/Variable.scss';
.Root{
    height: 100px;
    background: #ffffff;
    z-index: 10;
    padding:10px 8px;
    justify-content: center;
    display: flex;

    .menuontainer{
      @media (max-width: $breakpoint-xl) and (min-width:$breakpoint-large) {
        display:none;
      }
      @media (max-width: $breakpoint-large) and (min-width:$breakpoint-tablet) {
        display:none;
      }
      @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
          display: none;
      }
      @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
        display: inline-block;
        width: 100px;
      }

      .menubox{
        display: block;
      }
    }

  }
  
  .user{
    border-left: 1px solid #fafafa;
    width: 230px;
    height: 100%;
    position: relative;
    margin-left:auto;

    @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
      width: 90%;;
      display: inline-flex;
      justify-content: flex-end;
      margin-left: -4px;
    }
  }
  
  .toggle{
    padding:0px 5px;
    cursor: pointer;
    &:hover{
      .dropdown-item{
        display: block;
        width: 85%;
        //margin-top: 10px;
        padding: 8px 20px;
        color:white;
        background: #233269;
        &:hover{
          display: block;
          background: #87CEEB;
          color:white;
        }
        
      }
      
    }
  }
  
  .dropdown-item{
    display: none;
  }