@import '../../../styles/variables/Variable.scss';

//add hover color for sidebar menus exclusively for ship and drydok menu heading 
.sub_list{
    @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
        position:absolute;
        width: max-content;
        z-index: 2;
        box-shadow: 0 2px 5px 0 rgba(131, 141, 248, 0.05);
        background-color: #000000;
    }
    @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
        position:relative;
        width: max-content;
        z-index: 2;
    }
}
.mobileview{
    >div{
        &:hover{
            background-color: none !important;
        }
    }
    >div{
        &.sub_list{
            @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
                position:relative;
                width: max-content;
                z-index: 2;
                left:-10px;
                top:-40px;
            }
        }
    }
    @media (max-width: $breakpoint-large) and (min-width:$breakpoint-tablet) {
        display:none;
    }
    @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
        display: none;
    }
    @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
        display: block;
    } > div {
        display:none;
    }
}