@import '../../../styles/variables/Variable.scss';

//icon container style 
.iconcontainer{
    font-size: 18px;
    vertical-align: middle;
    //color:$white
}
.menuicon{
    position: relative;
    padding:0px;
    font-size: 30px;
    width: 30px;
    &:hover{
        background-color: none;
    }
    @media (max-width: $breakpoint-large) and (min-width:$breakpoint-tablet) {
        display:none;
    }
    @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
        display: none;
    }
    @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
        display: block;
    }
}
.closeicon{
    display: inline-block;
    width: 0%;
    position: relative;
    top:10px;
    left:-40px;
    vertical-align: top;
    margin-left: -4px;
}