@import '../../../styles/variables/Variable.scss';
// basic button style
.btn.bttn{
    width: auto;
    height: 40px;
    padding: 0px 15px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 2px;
    white-space: nowrap;
    appearance: button;
    cursor: pointer;
    pointer-events: auto;
    box-sizing: border-box;
    outline: none;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

    //styles are applied only when the button is active
    &:active{
        background-color: none !important ; 
        border-color: none !important;
        border: none
    }

    //styles are applied only when button disabled
    &:disabled{
    background: #cccccc;
    color: #888888;
    cursor: not-allowed;

    }
    & + button{
    margin-left: 15px;
    }

//set style for icon inside the button
    .material-icons{
    margin-right: 5px;
    }
    >div{
        >img{
            margin-right: 8px;
        }
    }
//when we use button varient as blue these styles are applied to that particular button. exclusively for variant blue
    &.bttn-blue {
        background-color:#233269 ;
        color: #ffffff;
        border: 1px solid transparent;
        // appled when hover the button
        &:hover{
            border: 1px solid #233269;
            background-color: $white;
            color: $primary;
        }
    }

//when we use button varient as white these styles are applied to that particular button. exclusively for variant white
    &.bttn-white {
        background: #ffffff;
        color: #233269;
        border: 1px solid #ffffff;

    }
//when we use button varient as transparent these styles are applied to that particular button. exclusively for variant transparent
    &.bttn-transparent {
        background: transparent;
        color:#233269;
        border: 1px solid #233269;
        //styles are applied when gover the button
        &:hover{
            background-image: linear-gradient(15deg, #0039a6, #87CEEB);
            color: #ffffff;
            border: 1px solid transparent;
        }
    }
//these style applied exclusively for login button
    &.bttn-login{
        background-image: linear-gradient(15deg, #0039a6, #87CEEB);
        border: none; 
        width: 100%;
    }

//these styles applied forgot password link
    &.bttn-transparent-forgot {
        background: none !important;
        color:#233269;
        border: none !important;
        text-transform: capitalize;
        box-shadow: none !important;
        padding: 0px;
        text-decoration: underline;
        font-size: 15px;
        text-align: left !important;
        width: 300px !important;
        height: auto !important;
        font-weight: normal;

        &:active{
            color:$primary;
        }
    }

//these styles applied for view edit delete icons in all list pages
    &.link_button{
        background: none !important;
        cursor: pointer;
        border: none;
        box-shadow: none !important;
        padding: 0px;
        appearance: link;
        color:$view_delete_edit_icon;
        height: auto;
        margin: 0px;
        //applied when icons are active
        &:active{
            color:$view_delete_edit_icon
        }
        //applied when hover the icon
        &:hover{
            color: $icon;
            border: none;
        }
        >div{
            >img{
                width: 16px;
            }
        }
    }
    &.disabled-btn{
        pointer-events: none;
    }
    &.filter{
        padding:8px;
        margin-left: 5px;
        border: 1px solid #233269;

        &:hover{
            color: $icon;
            border: 1px solid #233269;
        }

    }
    
}