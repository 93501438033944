@import '../../../styles/variables/Variable.scss';

.pagination{
  margin:0px !important;
  .page-item{
    //styles apply for active page link in pagination
    &.page-item.active .page-link{
      background-color:$page-item-bg;
    }
    //apply color for page link
    .page-link{
      color:$black;
    }
  }
}