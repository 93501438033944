@import '../../..//styles/variables/Variable.scss';

.sidebarmobile-container{
    box-shadow: 0 2px 5px 0 rgba(131, 141, 248, 0.05);
    background-image: linear-gradient(15deg, $primary, $danger);
    height:200vh;
    width: 100%;
    overflow: auto;
    position:relative;
    display: inline-block;
    >div{
      margin: 20px auto 20px auto;
      padding-top: 10px;
      width: 85px;
      > img {
          max-width: 100%;
          height: auto;
      }
    }
    >ul{
      list-style: none;
      padding-left: 0px;
      >li{ 
        >a{
          :hover{
            background-color: $grey;
            text-decoration: none;
          }
          >div{
            >span{
              color: $white !important;
            }
           }
        }
      }
      >li{
        margin-bottom: 10px;
        color:$white;
        >div{
  
          >div{
            >ul{
              >li{
                :hover{
                  background-color: $grey;
                }
              }
            }
            >div{
              >span{
                color:$white !important;
              }
            }
          }
        }
      }
      ul {
          list-style: none;
          padding-left: 0px;
            
          > li {
              position: relative;
            > a {
              display: flex;
              justify-content: flex-start;
              cursor: pointer;
              padding: 10px 30px 5px 65px;
              opacity: 0.7;
              color:black !important;
              &:active {
                > div {
                  opacity: 1;
                }
              &:hover{
                text-decoration: none;
                color:black
              } 
              }
              > div {
                opacity: 0.7;
              }
            }
          }
      }
      .toggleMobile{
          display: flex;
          justify-content: flex-start;
          cursor: pointer;
          padding: 10px 30px;
          opacity: 0.7;
          > i {
            width:20px;
            margin-right: 15px;
            color: $white;
          } 
          >img{
            width:20px;
            margin-right: 15px;
          }
      }
    }
    
  }
  