@import '../../../styles/variables/Variable.scss';
.txt{
    font-weight: 400;
    text-align: left;
    display: inline;
    text-transform: capitalize;
    &.txt-title{
        font-size: 30px;
        margin-bottom: 15px;
        font-weight: 300;
        text-transform: none;

        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            font-size: 22px;
            font-weight: 400;
        }
    }
    &.txt-title-sm{
        font-size: 20px;
        text-transform: none;

        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            font-size: 17px;
        }
    }
    &.txt-paragraph{
        font-size: 16px;
    }
    &.txt-subtitle{
        font-size: 14px;
    }
    &.txt-confirmation{
        font-size: 14px;
        text-transform: none !important;
    }
    &.txt-caption{
        font-size: 13px;
        text-transform: none;
    }
    &.txt-table-date{
        font-size: 14px;
    }
    &.txt-legend{
        text-transform: none;
    }
    &.txt-white{
        color:$white;
    }
    &.txt-black{
        color:$black;
    }
    &.txt-success{
        color:$success;
    }
    &.txt-danger{
        color:$danger;
    }
    &.txt-warning{
        color:$warning;
    }
    &.txt-main{
        color:$primary;
    }
    &.txt-tablehead{
        color:$primary;
    }
    &.txt-uppercase{
        text-transform: uppercase;
    }
    &.txt-tabledata{
        color:$table_data;
    }
    &.txt-capitalize{
        text-transform: capitalize;
    }
    &.txt-bold{
        font-weight: bold;
    }
    &.txt-center{
        text-align: center;
        display: block;
    }
    &.modal-title{
        display: block;
    }
    // &.email-txt{
    //     text-transform: none;
    // }
    &.login_card_title{
        text-transform: capitalize;
    }
    &.like-link{
        cursor: pointer;
        margin-left: 5px;
    }
    &.title-name{
        margin-left: 10px;
        text-transform: uppercase;
    }
    &.txt-email{
        text-transform: none;
    }
    &.goto_text{
        display: inline-block;
        margin-left: 15px;
    }
    &.import-txt{
       margin-left: 5px;
    }
    &.dashboard_subtitile{
        color: $primary;
        font-size: 18px;
        font-weight: 500;
    }
    &.dashboard_value{
        color: $primary;
        font-size: 16px;
        font-weight: 500;
    }
    &.loader{
        position: fixed;
        top: 30%;
        left: 50%;
    }
    &.dashboard_heading{
        color: $primary;
        font-size: 25px;
        font-weight: 600;
    }
}

