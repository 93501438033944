.bootstrap-card{
    padding: 20px 10px 20px 20px;

    .bootstrap-card-content{
        padding:20px 10px;

        >p{
            margin:0px;
        }
    }
}
