@import '../../../styles/variables/Variable.scss';

// common style applied for all Attribute  component
.attribute-container{
    padding: 20px 10px 20px 20px;
    @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
      padding: 0px 20px 10px;
    }
    @media (max-width: 1200px) and (min-width:992px) {
      padding: 20px 10px 20px 20px
    }
    @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
      padding: 10px 20px 10px;
    }
    > div {
    display: grid;
    grid-template-columns: 150px auto;
    align-items: center;

    @media (max-width: 400px)  {
      // grid-template-columns: 120px auto;
      display: block;
    }
    @media (max-width: 1200px) {
      grid-template-columns: 120px auto;
    }
    > span {
      font-weight: 300;
      @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
        display: block;
        margin-bottom: 10px;
      }

    }
    & + div {
      margin-top: 10px;
    }
  }

  &.modal-boxContainer{
    padding: 20px 0px;
  }

//exclusive style only for All view detail form 
  .view_Page{
    padding: 20px 10px 20px 20px;
    @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
      padding: 0px 20px 10px;
    }
    @media (max-width: 1200px) and (min-width:992px) {
      padding: 20px 10px 20px 20px
    }
    @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
      padding: 10px 20px 10px;
    }
    > div {
      display: grid;
      grid-template-columns: 110px auto;
      align-items: baseline;
      
      @media (max-width: 1200px) and (min-width:992px) {
        grid-template-columns: 120px auto;
      }
      > span {
        font-weight: 300;
      }
      & + div {
        margin-top: 10px;
      }
    }
  }
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block!important;
  vertical-align: middle;
  padding: 0px 10px;
}

.fileUpload {
  background-image: linear-gradient(15deg, #0039a6, #87CEEB) !important;
  color: #FFFFFF !important;
}
.upload-btn-wrapper::hover{
  cursor: pointer;
}


.singleFrame input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.singleFrame input[type=date] {
  width: 100%;
  color: transparent;
  border: none;
  background-color: transparent;
}

.singleFrame{
  padding: 20px 10px 20px 20px;
  @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
    padding: 0px 20px 10px;
  }
  @media (max-width: 1200px) and (min-width:992px) {
    padding: 20px 10px 20px 20px
  }
  @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
    padding: 10px 20px 10px;
  }
  > div {
    display: grid;
    grid-template-columns: 190px auto;
    align-items: baseline;
    
    @media (max-width: 1200px) and (min-width:992px) {
      grid-template-columns: 130px auto;
    }
    > span {
      font-weight: 300;
    }
    & + div {
      margin-top: 10px;
    }
  }
}
.dock-add-edit{
  padding: 20px 0px 20px 20px;
  @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
    padding: 0px 20px 10px;
  }
  @media (max-width: 1200px) and (min-width:992px) {
    padding: 20px 0px 20px 20px
  }
  @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
    padding: 10px 20px 10px;
  }
  > div {
    display: grid;
    grid-template-columns: 100px auto;
    align-items: baseline;
    
    @media (max-width: 1200px) and (min-width:992px) {
      grid-template-columns: 100px auto;
    }
    > span {
      font-weight: 300;
    }
    & + div {
      margin-top: 10px;
    }
  }
}
.forgotPassword_modal{
  padding: 20px 20px;
  > div {
    grid-template-columns: 70px auto;
  }
}
.dock-view{
  padding: 2px 10px;
  border: 1px solid #000000;
  margin-bottom: 5px;
  > div {
    grid-template-columns: 200px auto;
  }
}