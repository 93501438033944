@import '../../../styles/variables/Variable.scss';
//style apply for all labels
.label-basic{
    color: #ffffff;
    border-radius: 2px;
    padding: 5px 8px;
    //exclusive style for label which variant is 
    &.primary {
        color: #233269;
        font-size: 15px;
        text-align: left;
        width: 100%;
        padding: 0px;
    }
    //label for all detail page with background color yellow
    &.warning {
        background-color: $success;
        // margin-left: 10px;
    }
    //label for all detail page with background color red
    &.danger {
        background-color: $danger;
        // margin-left: 10px;
    }
}

