

*{
    box-sizing: border-box;
    outline: 0;
}
body{
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
a{
    color:#ffffff;
}
a:hover{
    color:#ffffff;
    text-decoration: none !important;
}
.app{
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}