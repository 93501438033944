@import '../../../styles/variables/Variable.scss';
.company-container{
    padding: 20px 25px 30px;
    border-top: 1px solid #eeeeee;
    > div {
    display: grid;
    grid-template-columns: 150px auto;
    > div {
      font-size: 14px;
    }
    & + div {
      margin-top: 25px;
    }
  }
}

.company-link-container{
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;

  &.warning{
    color:$warning;
  }

  &.danger{
    color:$danger;
  }
}
.show-all{
  padding:0px 20px
}
.dock-add{
  position: absolute;
  display: inline-block;
  left: 175px;
  top: 23px;
}