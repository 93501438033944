@import '../../../styles/variables/Variable.scss';

//style for root container in card component
.root-container{
    background-color: $white;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
    //title insind the card component
    .title-container{
        padding: 28px 23px;
        border-bottom: 1px solid $lightgrey;
        margin-bottom: 10px;
        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            padding: 28px 15px;
            margin-bottom: 30px;
        }
        > div {
            margin: 0px;
        }
    }
    &.profile-title{
        .title-container{
            padding: 28px 10px;
        }
    }
    &.login_card{
       
        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            height: 680px;
        }
        > div {
            height: 680px;
        }
    }
    &.DataShowingContainer{
        margin-top: 20px;
        min-height: 100px;
        text-align: center;
    }
}