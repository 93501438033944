@import '../../../styles/variables/Variable.scss';
//style for backdrop which is hide behind modal box where we set show model as true
.backdrop-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;

    //styles for content of the modal box
    .content-container{
        width: 480px;
        padding: 40px 0px 50px 0px;
        background: #ffffff;
        box-shadow: 0 0 5px 0 rgba(247, 174, 174, 0.53);
        position: relative;

        @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
            width: 400px;
        }
        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            width: 300px;
        }
        
        // styles for icon container (close icon inside the modal box)
        .icon-container{
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            padding: 8px;
        }

        
    }

    .import-mode{
        width: 638px;
        padding: 40px 0px 50px 0px;
        background: #ffffff;
        box-shadow: 0 0 5px 0 rgba(247, 174, 174, 0.53);
        position: relative;

        @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
            width: 400px;
        }
        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            width: 300px;
        }
        
        // styles for icon container (close icon inside the modal box)
        .icon-container{
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            padding: 8px;
        }
    }

    .import-new-mode{
        width: 1100px;
        padding: 40px 0px 50px 0px;
        background: #ffffff;
        box-shadow: 0 0 5px 0 rgba(247, 174, 174, 0.53);
        position: relative;
        height: 75%;
        overflow: auto;

        @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
            width: 400px;
        }
        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            width: 300px;
        }
        
        // styles for icon container (close icon inside the modal box)
        .icon-container{
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            padding: 8px;
        }
    }

    .dock-new-mode{
        width: 480px;
        padding: 40px 0px 50px 0px;
        background: #ffffff;
        box-shadow: 0 0 5px 0 rgba(247, 174, 174, 0.53);
        position: relative;
        height: 35%;
        overflow: auto;

        @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
            width: 400px;
        }
        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            width: 300px;
        }
        
        // styles for icon container (close icon inside the modal box)
        .icon-container{
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            padding: 8px;
        }
    }
}