@import '../../../styles/variables/Variable.scss';
//style apply for all controls.
.basecontrol{
    padding: 10px 10px;
    font-size: 0.9rem;
    border-radius: 2px;
    background: #ffffff;
    color: #000000;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #233269;

    &::placeholder{
        font-size: 0.8rem;
        color:#52575a;
    }
    //specify height for input box
    &.custome-input{
        height: 40px;
    }
    //specify height for Textarea
    &.custome-textarea{
        min-height: 80px;
        resize: none;
    }
    //specify height for select box
    &.cutome-selectInput{
        height: 40px;
        -webkit-appearance: none;
    }
    &.cutome-selectInputnxt{
        height: 40px;
        > i {
            position: absolute;
            right: 0px;
            left: auto;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
//style for input box with icon
.layout-input{
    position: relative;
    // margin-right: 20px;
    // width: 250px;
    //apply style for icon inside Input layout component
    > i {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    }
    //apply style for input box inside Input layout component
    > input {
    padding-left: 40px;
    }
}
.RadioButton{
    display: inline-block;
    :first-of-type{
        margin-right: 20px;
    }
}
.RadioButtonPage{
    display: inline-block;
    padding: 10px;
    :first-of-type{
        margin-right: 20px;
    }
}
//style for select box with icon
.layout-select{
    position: relative;
    cursor: pointer;
    > i {
        position: absolute;
        right: 0px;
        left: auto;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.option-placeholder{
    font-size: 13px;
    color:#52575a;
}
.err-msg{
    font-size: 12px;
    color: $danger;
    font-weight: 500;
}

// textarea{
//     resize: none;
// }