h1 {
    color: black;
    font-size: 30px;
    font-weight: 300;
}
.btn-primary{
    background-color: #233269;
    border-color: #233269;
    border-radius: 0px;
}
Button{
    width: 15%;
}
.card{
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
    border: 0px;
    border-radius: 0px;
}
.table th{
    border-top: none;
}
.services{
    display: block;
}