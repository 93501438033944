@import '../..//styles/variables/Variable.scss';

.dashboard{
   border: 1px solid $primary;
   padding: 28px 19px !important;
   border-radius: 10px;
   align-items: center;
}
.dashboard_col{
    min-height: 250px;
    margin-left: 4%;
}
.dashboard_false{
   min-height: 392px;
   margin-left: 4%;
 }
.dashboard_row{
    width: 100%;
    align-items: center;
}
.value{
    text-align: right;
}
.heading{
    text-align: left;
    margin-bottom: 17px !important;
}
.dashboard_heading{
    text-align: center
}

@media (max-width: 991px)
{
    .dashboard_false{
        margin-left: 0%;
        margin-bottom: 20px !important;
    }
    .dashboard_col{
        margin-left: 0%;
        margin-bottom: 20px !important;
    }
}
