h1 {
    color: black;
    font-size: 30px;
    font-weight: 300;
}
.btn-primary{
    background-color: #233269;
    border-color: #233269;
    border-radius: 0px;
}
Button{
    width: 15%;
}
.card{
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
    border: 0px;
    border-radius: 0px;
}
.table th{
    border-top: none;
}
.services{
    display: block;
}


.searchOwner-btn-container{
    display: block;
    
    .searchOwner-btn-add{
        display: inline-block;
        vertical-align: middle;
        width: 150px;
        margin-right: 10px;

        @media (max-width: 450px) {
            width: 150px;
            margin-right: 5px;  
        }
    }
    .searchOwner-btn-filter{
        display: inline-block;
        vertical-align: middle;
        width: auto;
        margin-right: 10px;

        @media (max-width: 450px) {
          width: 43px;
        }
    }
    .searchOwner-btn-showall{
        display: inline-block;
        vertical-align: middle;
        width: auto;

        @media (max-width: 450px) {
            width: auto;  
        }
        @media (max-width:414px) {
            display: block;
            margin-top: 10px;
        }
    }
}