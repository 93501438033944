@import '../../../styles/variables/Variable.scss';

//basic style
.table.table-basic{
    width: 100%;
    padding-bottom: 15px;
    //styles for table head
    th {
    text-align:center;
    color: #aaaaaa;
    font-weight: 500;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    //styles for first child of the table head
    &:first-child{
        padding-left:15px;
    }
    }
    //styles for table data
    td {
    padding: 15px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    color: #52575a;
        //styles for first child of the table data
        &:first-child{
            padding-left:15px;
        }
    }
    //table in drydock company
    &.table-company {
        th{
            &:nth-child(2){
                padding-left: 5px;
            }
            &:last-child{
                text-align:center;
            }
        }
        td{
            padding: 15px 5px;

            &:first-child{
                padding-left:15px;
                
            }
            &:nth-last-child(3){
                text-align:center;
            }
        }
        &.table-import {
          th{
              &:nth-child(2){
                  padding-left: 5px;
              }
              &:last-child{
                  text-align:center;
              }
          }
          td{
              padding: 15px 5px;
  
              &:first-child{
                  text-transform: uppercase;
                  padding-left:15px;
              }
              &:nth-last-child(3){
                  text-align:center;
              }
          }
      }
    }
}
.table {
    .td{
        padding:0px;
    }
}
.table.responsiveTable {
    width: 100%;
    // margin: 0;
    // padding: 0;
    // border-collapse: collapse;
    // border-spacing: 0;
    padding-bottom: 15px;
    thead {
      display: none;
      background: #ffffff;
      @media screen and (min-width: 996px) {
        display: table-header-group;
      }
    }

    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      margin:0px 10px 25px 10px;
      display: block;
      border:1px solid $table_data;
      @media screen and (min-width: 996px) {
        display: table-row;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        margin-bottom: 0;
        border:none;
      }
    }
    th,
    td {
      padding: 10px 4px;
      text-align: left;
      text-transform: capitalize;
      @media (min-width: 997px) and (max-width: 1199.98px)  {
        border-left: 1px solid rgba(0, 0, 0, 0.06) ;

        &:first-child{
            border-left: none;
        }
      }
      @media screen and (min-width: 996px) {
        &:first-child{
          padding-left:5px;
          padding-right: 5px;
        }
      }
     
    }
  
    th {
      font-size: 13px;
      color: $table_th;
      font-weight: 500;
      padding: 15px 0px;
      // text-align: center;
      &:first-child{
        padding-left:15px;
      }
    }
  
    td {
      display: block;
      text-align: right;
      font-size: 13px;
      color: $table_data;
      border-top: none !important;
      border-bottom: 1px dotted #52575a;
      &:last-child {
        border-bottom: none !important;
      }
      @media (min-width: 997px) and (max-width: 1199.98px)  {
        border-left: 1px solid rgba(0, 0, 0, 0.06) ;

        &:first-child{
            border-left: none;
        }
      }
      @media screen and (min-width: 996px) {
        display: table-cell;
        text-align: left;
        font-size: 14px;
        border-bottom: none;

        &:first-child{
            padding-left:15px;
        }
      }
    }
  
    td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: bold;
      @media screen and (min-width: 996px) {
        content: "";
        display: none;
      }
    }
}
  
  