//color

$primary: #233269;
$success: #4eba5c;
$danger: #eb2127;
$warning: #f4a82c;
$white : #ffffff;
$black : #000000;
$grey :  #AAAABC;
$table_data : #52575a;
$table_th :  #aaaaaa;
$errmsg : #D9302F;

//colors for icon
$icon :#233269;
$view_delete_edit_icon : #52575a;

//colors for paginaton
$lightgrey: #eeeeee;
$pagination-grey : #BABBBA;
$page-item-bg :  #fafafa;


// Media Query
$breakpoint-mid:890px;
$breakpoint-tablet:768px;
$breakpoint-mobile: 560px;
$breakpoint-xs:315px;
$breakpoint-large:1200px;
$breakpoint-xl:1750px;
