@import '../../../styles/variables/Variable.scss';
//basic style
.Grid-basic{
    display: grid;

    &.home_page{
        @media (max-width: $breakpoint-tablet) and (min-width:$breakpoint-mobile) {
            grid-template-columns:80px auto !important;
        }
        // @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
        //     grid-template-columns:40px auto !important;
        // }
        @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
            grid-template-columns:0px auto !important;
        }
    }
    &.add_page{
        @media (min-width:$breakpoint-xs) {
            grid-template-columns:100% auto !important;
        }
        @media (min-width:$breakpoint-mobile) {
            grid-template-columns:95% auto !important;
        }
        @media (min-width:992px) {
            grid-template-columns:75% auto !important;
        }
        
    }
    &.login_grid{
        @media (max-width: 767px) and (min-width:315px) {
            grid-template-columns:0% 100% !important;
        }
        @media (max-width: 997px) and (min-width:768px) {
            grid-template-columns:45% 45% !important;
        }
        @media (max-width: 1201px) and (min-width:998px) {
            grid-template-columns:49% 41% !important;
        }
        @media (max-width: 1500px) and (min-width:1200px) {
            grid-template-columns:55% 35% !important;
        }
        @media (max-width: 1800px) and (min-width:1501px) {
            grid-template-columns:55% 40% !important;
        }
        @media (max-width: 2200px) and (min-width:1801px) {
            grid-template-columns:60% 30% !important;
        }
        @media (max-width: 2500px) and (min-width:2201px) {
            grid-template-columns:60% 33% !important;
        }
    }
}
