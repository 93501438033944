.LoginForm{
    width: 270px;
    margin: 80px auto;
    text-align: center;
    > label {
      text-align: left;
      margin-bottom: 15px;
      display: block;
    }
    >div{
        text-align: center;
        > img {
            width: 217px;
            height: 121px;
            margin: 0px auto;
        }
    }
}

.root{
  background-image: linear-gradient(55deg, #233269, #eb2127);
  height: 100vh;
  width: 100vw;
}

.resetCard{
  width: 815px;
  padding: 82px 0px 105px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resetForm{ 
  width: 270px;
  img {
    max-width: 100%;
    height: auto;
  }
}

// .primary {
//     color: #233269;
//     font-size: 15px;
//     text-align: left;
//     width: 100%;
//     padding-left: 0px;
// }
// .root{
//     background-image: linear-gradient(55deg, #233269, #eb2127);
//     display: flex;
//     a{
//         text-decoration : underline !important;
//         cursor: pointer;
//     }
//     .bannerContainer{
//         max-width: 100%;
//         height: auto;
//     } 

//     .LoginCard{
//         height: 657px;
//         width:75%;
//         background-color: #ffffff;
//         display: inline-block;
//         .LoginForm{
//             width: 274px;
//             margin: 80px auto;
//             text-align: center;
//             .logoContainer{
//                 text-align: center;
//                 > img {
//                     width: 217px;
//                     height: 121px;
//                     margin: 0px auto;
//                 }
//             }
//         }
//     }
// }
