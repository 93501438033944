@import '../../../styles/variables/Variable.scss';

//basic props 
.flx{
    display: flex;
//styles apply based on justify content
    &.jc-start{
        justify-content: start;
    }
    &.jc-left{
        justify-content: left;
    }
    &.jc-right{
        justify-content: right;
    }
    &.jc-end{
        justify-content: end;
    }
    &.jc-flexend{
        justify-content: flex-end;
    }
    &.jc-between{
        justify-content: space-between;
    }
    &.jc-center{
        justify-content: center;
    }
    &.jc-around{
        justify-content: space-around;
    }
    &.jc-strech{
        justify-content: stretch;
    }
    &.jc-checkbox{
        display: block;
    }
//styles apply based on align item
    &.ai-start{
        align-items:initial;
    }
    &.ai-end{
        align-items: flex-end;
    }
    &.ai-center{
        align-items: center;
    }
    &.ai-baseline{
        align-items: baseline;
    }
    &.ai-stretch{
        align-items: stretch;
    }
    &.Action-container{
        margin-top: 20px;
    }
    // &.LinkAction-container{
    //     margin-top: 20px;
    //     margin-bottom: 20px;
    //     margin-right: 10px
    // }
    &.tablefooter{
        padding: 0px 15px 20px;
    }
    &.tableAction{
        > a {
            display: block;
            padding: 0px 5px;
            cursor: pointer;
        
            &:hover {
                > i {
                color: #2c6df4;
                }
            }
            
            & + a {
                border-left: 1px solid rgba(0, 0, 0, 0.06);
            }
        }
    }
    &.login_root{
        background-image: linear-gradient(15deg, #0039a6, #87CEEB);
        height: 100vh;
        width: 100%;
    }
    &.login_banner{
        >img{
            max-width: 100%;
            height: auto;
        }
    }
    &.searchArea{
        @media (max-width: 950px){
            display: block;
            >div{
                >input{
                    width: 260px;
                }
            }
            >button{
                margin-top: 10px;
                margin-left: 0px;
            }
            >a{
                >button{
                    margin-top: 10px;
                    margin-left: 0px;
                }
            }
        }
    }
}