@import '../../../styles/variables/Variable.scss';
.modalbody-container{
    padding: 0px 55px;

    @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
        padding: 0px 20px;
    }
    .attribute-container{
        > div {
            display: grid;
            grid-template-columns: 42% auto;
            align-items: center;

            @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
                grid-template-columns: 40% auto;
            }
            > div {
                font-size: 14px;
                font-weight: 300;
            }
            & + div {
                margin-top: 10px;
            }
        }
    }
}
.data-list{
    padding: 10px;
    height: 40px;
    font-size: 0.9rem;
    border: 1px solid $primary;
}
.serachForm{
    // width: 100%;
    display: block;
    padding: 25px 10px 15px 20px;
    margin: 0px;
    // @media (max-width: 950px){
    //     width: max-content;
    // }
    // button {
    //     margin-left: 10px;
    // }
    // select{
    //     margin-left: 10px;
    //     width: 200px;
    // }
    // @media (max-width: 950px)  {
    //     input{
    //         display: block;
    //     }
    //     button{
    //         display: block;
    //         margin-left: 0px;
    //     }
    //     select{
    //         display: block;
    //         margin-left: 0px;
    //         margin-top: 10px;
    //     }
    // }
}
.user-filter{
    display: block;
    padding: 0px 20px;
    margin-bottom: 10px;
    width: 620px;
    .byStatus{
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        width: 200px;

        @media (max-width: 480px){
            display: block;
        }
    }
    .byRole{
        display: inline-block;
        vertical-align: middle;
        margin-left: -4px;
        width: 200px;

        @media (max-width: 480px){
            display: block;
            margin-left: 0px;
            margin-top: 10px;
        }
    }
}
.phnnum-container{
    display: block;
    >select{
        width: 26%;
        padding:10px 0px 10px 0px;
        margin-right: 2%;
        display: inline-block;
        vertical-align: middle;
    }
    >input{
        display: inline-block;
        width: 71%;
        vertical-align: middle;
    }
}
.phnnum-container-company{
    display: block;
    >select{
        width: 15.5%;
        padding:10px 0px 10px 0px;
        margin-right: 2%;
        display: inline-block;
        vertical-align: middle;
    }
    >input{
        display: inline-block;
        width: 82.5%;
        vertical-align: middle;
    }
}
.title-name-block{
    display: block;
    >div{
        display: inline-block;
        width: 75px;
        height: 75px;
        vertical-align: super;
        margin-right: 20px;
        @media (max-width:345px) {
            width: 50px;
            height: 50px;
            margin-right: 5px;
        }
        >img{
            width: 100%;
            height: 100%;
        }
    }
    .imageHeader{
        width: 0px;
        margin-right: 0px;
        .custom-file-upload{
            display: inline-block;
            cursor: pointer;
            margin-bottom: 0px;
            width: 30px;
            position: relative;
            left:-37px;
            bottom:-20px;
            @media (max-width:350px) {
                left: -20px;
                bottom: -25px; 
            }
            >img{
                width: 100%;
            }
        }
        input[type="file"] {
            display: none;
        }
    }
    >span{
        display: inline-block;
        width: auto;
        vertical-align: middle;
        margin-right: 5px;

        @media (max-width:345px) {
            font-size: 20px !important;
        }

    }
    >label{
        display: inline-block;
        width: auto;
        vertical-align: middle;

        @media (max-width:345px) {
            font-size: 12px;
        }
    }
}
.user-mainBlock{
    text-align: center;

    .userEdit-block{
        display: inline-block;
        width: 150px;
        height: 1500px;
        text-align: center;
        margin: 0px auto;
        
        @media (min-width: 992px){
            width: 120px;
            height: 120px; 
        }
        @media (min-width: 1200px) {
            width: 150px;
            height: 150px;
        }
        >img{
            width: 100%;
            height: 95%;
            
        }
    }
    .userProfile-block{
        display: inline-block;
        width: 200px;
        height: 200px;
        text-align: center;
        margin: 0px auto;
        
        @media (min-width: 992px){
            width: 190px;
            height: 190px; 
        }
        @media (min-width: 1200px) {
            width: 200px;
            height: 200px;
        }
        >img{
            width: 100%;
            height: 95%;
            
        }
    }
    .userCompany-block{
        display: inline-block;
        width: 250px;
        height: 200px;
        text-align: center;
        margin: 0px auto;
        
        @media (min-width: 992px){
            width: 190px;
            height: 196px; 
        }
        @media (min-width: 1200px) {
            width: 250px;
            height: 200px;
        }
        >img{
            width: 100%;
            height: 95%;
            
        }
    }
}

.search-btn-container{
    display: block;
    
    .search-btn-add{
        display: inline-block;
        vertical-align: middle;
        width: auto;
        margin-right: 10px;

        @media (max-width: 450px) {
            width: auto;
            margin-right: 5px;  
        }
    }
    .search-btn-filter{
        display: inline-block;
        vertical-align: middle;
        width: auto;
        margin-right: 10px;

        @media (max-width: 450px) {
          width: 43px;
        }
    }
    .search-btn-showall{
        display: inline-block;
        vertical-align: middle;
        width: auto;

        @media (max-width: 450px) {
            width: auto;  
        }
        @media (max-width:414px) {
            display: block;
            margin-top: 10px;
        }
    }
}
.blue-img{
    display: none;
    width: 20px;
}
.grey-img{
    display: block;
    width: 20px;
}
.revoke-block{
    &:hover{
        >button{
            >div{
                .grey-img{
                    display: none;
                    margin: 0px;
                    width: 0px;
                } 
                .blue-img{
                    display: inline-block;
                    width: 20px;
                    margin: 0px;
                }
            }
        }      
    }
}

// .blue-img{
//     display: none;
// }