@import '../../../styles/variables/Variable.scss';
.company-container{
    padding: 20px 25px 30px;
    border-top: 1px solid #eeeeee;
    > div {
    display: grid;
    grid-template-columns: 150px auto;
    > div {
      font-size: 14px;
    }
    & + div {
      margin-top: 25px;
    }
  }
}

.company-link-container{
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;

  &.warning{
    color:$warning;
  }

  &.danger{
    color:$danger;
  }
}
.show-all{
  padding:0px 20px
}
.dock-add{
  position: absolute;
  display: inline-block;
  left: 175px;
  top: 23px;
}

.imageEditBlock{
  padding: 15px;
  text-align: center;
  .custom-file-upload{
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 0px;
    >div{
      >i{
        margin-right: 5px;
      }
    }
  }
  input[type="file"] {
    display: none;
  }
}
.carousel-indicators li{
      background-color: black;
      color: black;
}
.carousel-control-prev{
  color: black;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}
.imageProfilePic{
  padding: 15px;
  text-align: center;
  .custom-file-upload{
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 0px;
    >div{
      >i{
        margin-right: 5px;
      }
    }
  }
  input[type="file"] {
    display: none;
  }
}
.editIconRemove{
  width: 52px;
}
.no_user{
  position: relative;
  top: 20px;
}