@import './Variable.scss';
.swal-modal{
    width: 300px !important;
    @media (max-width: $breakpoint-mobile) and (min-width:$breakpoint-xs) {
        width: 250px !important;
    }
    .swal-icon:first-child{
        margin-top: 10px !important;
    }
    .swal-title{
        padding: 0px !important;
    }
    .swal-footer{
        padding: 5px 5px;
    }
}
.swal-button{
    font-weight: 400 !important;
    background-image: linear-gradient(15deg, #0039a6, #87CEEB) ;
    background-color: none!important;
    width: 100px;
}

.styleTitle{
    .swal-footer{margin-bottom: 10px;}
    .swal-button--cancel{color: #ffffff;}
}