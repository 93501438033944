h1 {
    color: black;
    font-size: 30px;
    font-weight: 300;
}
.btn-primary{
    background-color: #233269;
    border-color: #233269;
    border-radius: 0px;
}
.date-picker-css{
    width: 20px;
    color: black;
    border: transparent;
    // display: none;
//    -moz-border:1px solid black;
}

.search-btn-container{
    display: block;
    
    .search-btn-add{
        display: inline-block;
        vertical-align: middle;
        width: auto;
        margin-right: 10px;

        @media (max-width: 450px) {
            width: auto;
            margin-right: 5px;  
        }
    }
    .search-btn-filter{
        display: inline-block;
        vertical-align: middle;
        width: auto;
        margin-right: 10px;

        @media (max-width: 450px) {
          width: 43px;
        }
    }
    .search-btn-showall{
        display: inline-block;
        vertical-align: middle;
        width: auto;

        @media (max-width: 450px) {
            width: auto;  
        }
        @media (max-width:414px) {
            display: block;
            margin-top: 10px;
        }
    }
}

.serachForm{
    // width: 100%;
    padding: 25px 10px 15px 20px;
    margin: 0px;
    // @media (max-width: 950px){
    //     width: max-content;
    // }
    // button {
    //     margin-left: 10px;
    // }
    // select{
    //     margin-left: 10px;
    //     width: 200px;
    // }
    // @media (max-width: 950px)  {
    //     input{
    //         display: block;
    //     }
    //     button{
    //         display: block;
    //         margin-left: 0px;
    //     }
    //     select{
    //         display: block;
    //         margin-left: 0px;
    //         margin-top: 10px;
    //     }
    // }
}
.view_page_xs_margin{
    margin-top: 30px;
}
.form-check-label{font-size: 14px; color: #eb2127;}

@media (min-width: 315px)
{
    .modal-dialog {
        max-width: 500px;
        margin: 200px auto;
    }
}


